@import '~@bottomless/common/style/common';

.text-md {
  font-size: medium;
}

.discount-code-wrapper {
  .text-danger {
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
  }

  .discount-code {
    gap: 0.1rem;
  }

  .discount-code > span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .discount-code > span:nth-of-type(1) {
    padding: 0.4rem 1rem;
  }

  .discount-code > span:nth-of-type(2) {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }

  .discount-code > span:nth-of-type(2):hover {
    cursor: pointer;
  }
}

@import '~@bottomless/common/style/common';

.wishlist {
  .dragging-over {
    background: $gray-300;
  }

  &-image {
    height: 60px;
    width: 60px;
  }

  &-product-name {
    font-weight: 600;
  }

  &-row {
    background: $white;

    &:hover {
      background: $light;
      border-radius: $card-border-radius;
    }
  }

  &-move {
    background: $gray-200;
    border-radius: $card-border-radius;
    color: $gray-600;
    cursor: pointer;
    white-space: nowrap;

    svg:first-child {
      margin-right: -10px;
    }
  }

  @include media-breakpoint-down(sm) {
    &-image {
      max-width: 60px;
    }

    &-vendor-name {
      @include font-size-rem(0.75);
    }

    &-product-name,
    &-price {
      @include font-size-rem(0.8);
    }
  }
}

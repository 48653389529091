.switch-to-sbu {
  &-title {
    font-size: 1.25em;
    font-weight: 600;
  }

  &-main-image {
    max-width: 360px !important;
  }

  &-feature {
    font-size: 0.875em;
  }

  &-feature-image {
    width: 100px;
    height: 100px !important;
  }

  &-feature-wrapper {
    flex: 1 0 100px;
  }

  &-disclaimer {
    font-size: 0.875em;
  }

  &-info {
    flex: 1 0 24px;
  }
}

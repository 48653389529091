@import '~@bottomless/common/style/common';

.modal-simple-subscription-product {
  .product-name-vendor {
    display: none;
  }

  .simple-subscription-product-loading {
    height: 2em;
  }

  .lazy-load-image-loaded {
    width: 73px;
  }

  .buttons-amount .btn:focus {
    box-shadow: none;
  }

  @include media-breakpoint-down(sm) {
    .add-new-products {
      width: 100%;
    }
  }
}

@import '~@bottomless/common/style/common';

.page-order {
  .product-vendor {
    display: none;
  }

  .discount-code {
    .badge {
      font-size: 100% !important;
      padding: 0.4rem 0.5rem;
    }
  }

  .react-datepicker-wrapper {
    width: 0;
    position: absolute;
    left: calc(50% + 3px);
  }

  .delay-order {
    width: 24px;

    label.triangle-right {
      flex-direction: row-reverse;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1px;
    }
  }

  .order-subproduct.selected .order-subproduct-wrapper {
    border-color: $body-color !important;
  }

  .form-feedback {
    .row {
      margin-right: -8px;
      margin-left: -8px;
    }
    .col-4 {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .form-feedback .form-check {
    padding-left: 0;

    &.radio-box {
      position: relative;

      h6 {
        font-size: 0.9em;
      }

      .radio-box-label.ml-3 {
        margin-left: 0 !important;
      }

      .text-uppercase {
        text-transform: none !important;
      }

      .form-check-label svg {
        position: absolute;
        top: 7px;
        left: 7px;
      }
    }
  }

  .charge-failure-icon {
    background: $danger;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
    padding-left: 1px;
  }

  @include media-breakpoint-down(sm) {
    .product-image {
      width: 75px;
      height: 75px;
    }
  }
}

@import '~@bottomless/common/style/common';

.tracking-timeline {
  &-time {
    flex: 1;
    padding-right: 2em;
    padding-bottom: 2.5rem;
  }

  &-data {
    flex: 2;
    padding-left: 2em;
    padding-bottom: 2.5rem;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      background: $body-color;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -14px;
      top: -2px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: $white;
      border: 2px solid $body-color;
    }
  }

  &-icon {
    position: absolute;
    left: -7px;
    top: 5px;
    z-index: 1;
  }

  &-row:first-child {
    .tracking-timeline-data {
      &:before {
        top: 12px;
      }
    }
  }

  &-row:last-child {
    .tracking-timeline-data {
      &:before {
        bottom: auto;
        height: 12px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &-time {
      padding-right: 1.5em;
    }

    &-data {
      padding-left: 1.5em;
    }
  }
}

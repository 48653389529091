@import '~@bottomless/common/style/common';

.stop-circle {
  circle {
    stroke: none;
  }
  rect {
    stroke: white;
  }
}

@import '~@bottomless/common/style/common';

.page-subscription-by-usage {
  .box-subscribe-by-usage,
  .subscribe-by-usage {
    padding: 0 !important;
    background: none;

    .text-center {
      text-align: left !important;
    }
  }

  .switch-to-sbu {
    .mw-360,
    .mw-300 {
      max-width: none !important;
    }

    .switch-to-sbu-feature-wrapper {
      flex: initial !important;

      & + div {
        flex: 1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .switch-to-sbu {
      .switch-to-sbu-feature.flex-row-reverse {
        flex-direction: row !important;

        .switch-to-sbu-feature-wrapper + .mr-4 {
          margin-left: 1.5em !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

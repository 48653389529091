@import '~@bottomless/common/style/common';

.modal-add-new-product {
  .product {
    @include transition;

    &:hover {
      @include transition;
      background: $gray-100;
    }
  }
}

@import '~@bottomless/common/style/common';

.page-settings {
  .checkbox-notification {
    .form-check {
      padding-left: 0;
    }

    .checkbox-label {
      flex: 1;
    }

    .checkbox-label .small {
      font-size: 1em;
    }

    input[type='checkbox'] {
      display: none;
    }

    h6.text-uppercase {
      font-weight: 700;
      text-transform: none !important;
      margin-bottom: 0.2em;
    }

    .check-box .form-check-label {
      border: none;
      padding: 0;
    }

    svg {
      margin-bottom: 2px;
    }
  }

  .input-account-name .form-control {
    flex-grow: 1;
  }
}

.input-date-calendar .icon-calendar {
  @include absolute(39px, 12px, null, null);
}

.form-sbu {
  .mw-325 {
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-down(sm) {
    .mw-325 {
      max-width: none;
    }
  }
}

@import '~@bottomless/common/style/common';

.modal-ordering-strategy {
  @include media-breakpoint-up(md) {
    .modal-body {
      width: 80%;
      margin-left: 10%;
      text-align: center;
    }
  }
}

.step-resume-subscription {
  .product-name-vendor {
    display: none;
  }
  .user-product {
    .extra-small {
      font-size: 0.8em;
    }
  }
}

.flex-gap-1 {
  gap: 1rem;
}

@import '~@bottomless/common/style/common';

.page-data {
  .info-number {
    &-value {
      font-size: 1.2em;
    }

    &-text {
      font-size: 0.8em;
    }
  }

  .graph-readings {
    display: none;
  }

  .profile-graph-empty-state {
    @include absolute;
  }

  .data-months .dc-chart .bar,
  .data-roast .dc-chart .pie-slice,
  .daily-consumption-graph .dc-chart .bar {
    cursor: pointer;
  }

  .data-months .dc-chart .bar.deselected,
  .daily-consumption-graph .dc-chart .bar.deselected,
  .data-roast .dc-chart .pie-slice.deselected path {
    fill: #b8b8b8 !important;
  }

  .data-months .dc-chart .bar:hover,
  .daily-consumption-graph .dc-chart .bar:hover,
  .data-roast .dc-chart .pie-slice:hover path {
    filter: opacity(0.6);
  }

  @include media-breakpoint-down(md) {
    .scale-data-row {
      margin-right: -7px;
      margin-left: -7px;

      > .col-6 {
        flex-grow: 1;
        max-width: none;
        padding-right: 7px;
        padding-left: 7px;
      }

      .card-body {
        padding: 0.5em;
      }

      .info-number-value {
        font-size: 1em;
      }

      .info-number-text {
        font-size: 0.65em;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .profile-graph-empty-state {
      .box {
        padding: 0.95em;
      }

      .mb-3 {
        margin-bottom: 0.75em;
      }
    }
  }
}

@import '~@bottomless/common/style/common';

.transition-slider {
  height: 4px;
  margin: 8px auto;
  border-radius: 6px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10%;
    right: 10%;
    border-radius: 6px;
    background: $gray-300;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10%;
    border-radius: 6px;
    background: $body-color;
  }

  .transition-slider-progress[data-status='scheduled'] {
    left: 10%;
  }

  &[data-status='fulfilling']:after {
    right: 70%;
  }

  .transition-slider-progress[data-status='fulfilling'] {
    left: 30%;
  }

  &[data-status='in_transit']:after {
    right: 50%;
  }

  .transition-slider-progress[data-status='in_transit'] {
    left: 50%;
  }

  &[data-status='out_for_delivery']:after {
    right: 30%;
  }

  .transition-slider-progress[data-status='out_for_delivery'] {
    left: 70%;
  }

  &[data-status='delivered']:after {
    right: 10%;
  }

  .transition-slider-progress[data-status='delivered'] {
    left: 90%;
  }

  &-progress {
    position: absolute;
    top: -3px;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    background: $gray-300;
    border-radius: 50%;
    box-sizing: content-box;

    &.status-dark {
      background: $body-color;
    }

    &.status-selected {
      top: -9px;
      margin-left: -10px;
      height: 20px;
      width: 20px;
    }
  }

  &-status {
    font-size: 0.7em;

    .status-label {
      flex: 1;
      word-break: keep-all;
    }
  }
}

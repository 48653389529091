@import '~@bottomless/common/style/common';

.heatmap {
  width: 100%;
  overflow: auto;
  margin: 5em 0em;

  .js-heatmap {
    width: 800px;
  }

  .day {
    fill: #eee;
    stroke: #fff;
    stroke-width: 2px;
  }

  .color0 {
    fill: #d6e685;
  }
  .color1 {
    fill: #44a340;
  }
  .color2 {
    fill: #28752d;
  }
  .color3 {
    fill: #1e6823;
  }
  .color4 {
    fill: #365e2f;
  }
  .color5 {
    fill: #144e12;
  }
}

@include media-breakpoint-down(md) {
  .heatmap {
    align-items: stretch !important;
  }
}

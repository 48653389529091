@import '~@bottomless/common/style/common';

.page-rate-order {
  .order-likes {
    right: 0;
    top: 0;
  }

  .feedback .selected {
    color: $danger;
  }
}

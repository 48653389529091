.order-product {
  .opacity-1 {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .opacity-0 {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .btn-more {
    outline: none !important;
    box-shadow: none !important;

    svg {
      transition: transform 0.3s ease;
    }

    &-active svg {
      transform: rotate(180deg);
    }
  }

  &-image .lazy-load-image-background {
    width: 65px;
    height: 65px;
  }
}

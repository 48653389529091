@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.simple-subscription-section {
  justify-content: center;
  cursor: pointer;
  gap: 6px;
}

.simple-subscription-frequency-modal {
  .mw-325 {
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-down(sm) {
    .mw-325 {
      max-width: none;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import '~@bottomless/common/style/common';

.page-new-subscription-summary {
  .checkout-quantity {
    top: -10px;
    right: -7px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary;
    color: $white;
    border-radius: 50%;
  }
}

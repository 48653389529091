@import '~@bottomless/common/style/common';

.page-orders {
  .badge-one-off,
  .badge-issue {
    position: absolute;
    right: 0;
    top: 0;
  }

  .badge-issue.badge-danger {
    background-color: $danger;
  }

  .order-product-name {
    .small {
      font-size: 1em !important;
    }
  }

  .box.box-order {
    padding-top: 1.25em !important;
  }

  .section-trigger-now {
    .info-icon {
      border: 1px solid $secondary;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.choose-portal-accounts {
  max-width: 520px;
  margin: 0 auto;

  .account-avatar {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }

  .account-name .small {
    font-size: 1em !important;
  }

  .account-container {
    justify-content: space-between;

    &-selected {
      background-color: #f2f2f2;
    }

    .info-wrapper {
      flex: 1;
    }
  }

  .small {
    font-size: 75%;
  }

  .inactive-badge {
    padding: 0.75rem;
    font-weight: 600;
    color: #8e9399;
    background: #dfdfdf;
  }

  .account-container:hover {
    text-decoration: none;
  }
}

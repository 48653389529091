@import '~@bottomless/common/style/common';

.magic-link-sent {
  .check-wrapper {
    background: $success;
    border-radius: 50%;
  }
}

.link-alert-container {
  .link-alert-box {
    background: $gray-200;
    display: none;
  }
  .link-alert-box:hover {
    display: block;
  }
  .link-alert-box.pinned {
    display: block;
  }
}

.link-alert-container:hover {
  .link-alert-box {
    display: block;
  }
}

.login-wild-earth {
  .login-wild-earth-wrapper {
    max-width: 450px;
  }

  .login-wild-earth-left {
    position: relative;
    padding-top: 65px;
    padding-bottom: 65px;
  }

  @include media-breakpoint-down(md) {
    .login-wild-earth-left {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 2em;
    }
  }

  img.rounded {
    border-radius: 8px !important;
  }

  .link-alert-container .link-alert-box {
    position: absolute;
    bottom: 0;
  }
}

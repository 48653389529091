@import '~@bottomless/common/style/common';

.page-profile {
  .user-product {
    .extra-small {
      font-size: 0.8em;
    }
  }

  .product-name {
    font-size: 0.875em;

    &-vendor {
      display: none;
    }
  }

  .box-order .product-name {
    font-size: 1em;
  }

  .order-history-first .text-nowrap {
    white-space: normal !important;
  }

  .image-heatmap {
    min-height: 70px;
    height: 100%;
    object-fit: cover;
  }

  .box.box-sm {
    padding: 0.5em;
  }

  .box-no-arrow .box-chevron {
    display: none;
  }

  .button-trigger-now {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .box-ordering-strategy {
    position: relative;

    .box-ordering-edit {
      position: absolute;
      right: 0.5em;
      top: 0.5em;
    }
  }

  .upcomming-orders .box ~ .box {
    margin-top: 1.5em;
  }

  .image-scale {
    max-height: 60px;
  }

  .scale-readings {
    .box {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }

    .text-right {
      text-align: left !important;
    }

    .justify-content-end {
      justify-content: start !important;
    }

    .col-12 {
      min-height: 60px;
    }
  }

  .chartjs-render-monitor {
    height: 70px !important;
    width: 100% !important;
  }

  .scale-status {
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .scale-status-text {
    flex: 1 0;
  }

  .scale-icon {
    width: 22px;
  }

  .box-heatmap a.box-link {
    display: flex !important;
    flex: 1;
  }

  .section-scale {
    margin-bottom: 0 !important;
  }

  .queue-more-items {
    margin-left: 30px;
  }

  @include media-breakpoint-down(lg) {
    .image-scale {
      padding-right: 0.75em;
    }
  }

  @include media-breakpoint-down(md) {
    h5 {
      font-size: 1.15em;
    }
  }
}

.modal-fixed-subscription {
  .form-fixed-period {
    .btn {
      outline: none !important;
      box-shadow: none !important;

      &-light {
        background-color: $gray-200;
        border-color: $gray-200;
      }
    }
  }
}

.modal-ordering-strategy {
  .radio-box {
    padding-left: 0;
  }
  .radio-box-label > div {
    text-align: left;
  }
}

.modal-queue {
  .text-strikethrough {
    text-decoration: line-through;
  }

  @include media-breakpoint-down(sm) {
    .wishlist-product-name {
      font-size: 12px;
    }

    .wishlist-vendor-name {
      font-size: 12px;
    }

    .wishlist-product-name,
    .wishlist-price {
      font-size: 12px;
    }
  }

  .wishlist-move > svg {
    margin-bottom: 2px;
  }
}

.tooltip-safe {
  bottom: calc(100% + 0.8em);
  left: calc(50% - 100px);

  &.tooltip:not(.show) {
    display: none;
  }

  .tooltip-inner {
    width: 200px;
  }

  .arrow {
    left: 50%;
    margin-left: -0.4em;
  }
}

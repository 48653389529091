.new-subscription-phone {
  .form-check {
    padding-left: 0;
    margin-bottom: 0.5em !important;
  }

  .text-uppercase {
    text-transform: none !important;
  }

  .radio-box .form-check-label {
    border: none;
    padding: 0;
  }
}
